<!-- src/app/components/pages/under-construction/under-construction.component.html -->
<div class="under-construction-area">
    <div class="container">
      <div class="content">
        <div class="logo">
          <img src="assets/images/logo.png" alt="VisiAI Logo">
        </div>
        
        <div class="under-construction-content" *ngIf="!isAuthenticated">
          <h1>We're Coming Soon</h1>
          <p>Our website is currently under construction. We are working hard to bring you an amazing experience.</p>
          
          <div class="login-form">
            <h3>Admin Access</h3>
            <div class="form-group">
              <input 
                type="password" 
                [(ngModel)]="password" 
                class="form-control" 
                placeholder="Enter password" 
                (keyup.enter)="onSubmit()">
            </div>
            <div class="error-message" *ngIf="error">{{ error }}</div>
            <button class="box-btn" (click)="onSubmit()">Login</button>
          </div>
        </div>
        
        <div class="authenticated-content" *ngIf="isAuthenticated">
          <h1>Welcome to VisiAI</h1>
          <p>Thank you for accessing our website. We are currently working on some exciting updates.</p>
          <p>Our site will be back online soon with a fresh new look!</p>
          
          <div class="countdown">
            <div class="contact-info">
              <h3>Contact Us</h3>
              <ul>
                <li><i class="bx bxs-phone-call"></i> +60 (12)-954 8088</li>
                <li><i class="bx bxs-envelope"></i> sales&#64;visiai.my</li>
                <li><i class="bx bxs-map"></i> SUITE AB NO. 5B, JALAN 15/1B, SEKSYEN 15 BANDAR BARU BANGI, SELANGOR, 43650, Malaysia</li>
              </ul>
            </div>
            
            <div class="action-buttons">
              <button class="box-btn" (click)="logout()">Logout</button>
            </div>
          </div>
        </div>
        
        <div class="footer">
          <p>© VisiAi 2024 - All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>