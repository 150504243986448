// src/app/components/pages/under-construction/under-construction.component.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrls: ['./under-construction.component.scss']
})
export class UnderConstructionComponent implements OnInit {
  password: string = '';
  error: string = '';
  isAuthenticated: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Check if user is already authenticated
    const authenticated = sessionStorage.getItem('authenticated');
    if (authenticated === 'true') {
      this.isAuthenticated = true;
    }
  }

  onSubmit(): void {
    if (this.password === 'visiai.my') {
      this.isAuthenticated = true;
      sessionStorage.setItem('authenticated', 'true');
	window.location.href="/home"
      this.error = '';
    } else {
      this.error = 'Invalid password. Please try again.';
    }
  }

  navigateToHome(): void {
    this.router.navigate(['/']);
  }

  logout(): void {
    this.isAuthenticated = false;
    sessionStorage.removeItem('authenticated');
	window.location.href="/"
  }
}