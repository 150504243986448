import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/case-studies-details/case-studies-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { AuthGuard } from './gaurds/auth.guard';
import { UnderConstructionComponent } from './components/pages/under-construction/under-construction.component';

const routes: Routes = [
    // Set under construction as the default route
    {path: '', component: UnderConstructionComponent},
    
    // Original routes (can still be accessed after authentication)
    {path: 'home', component: HomeThreeComponent, canActivate: [AuthGuard]},
    {path: 'home-two', component: HomeTwoComponent, canActivate: [AuthGuard]},
    {path: 'home-three', component: HomeOneComponent, canActivate: [AuthGuard]},
    {path: 'about', component: AboutComponent, canActivate: [AuthGuard]},
    {path: 'service', component: SolutionsComponent, canActivate: [AuthGuard]},
    {path: 'solutions-details', component: SolutionsDetailsComponent, canActivate: [AuthGuard]},
    {path: 'case-studies', component: CaseStudiesComponent, canActivate: [AuthGuard]},
    {path: 'case-studies-details', component: CaseStudiesDetailsComponent, canActivate: [AuthGuard]},
    {path: 'blog', component: BlogComponent, canActivate: [AuthGuard]},
    {path: 'blog-details', component: BlogDetailsComponent, canActivate: [AuthGuard]},
    {path: 'team', component: TeamComponent, canActivate: [AuthGuard]},
    {path: 'pricing', component: PricingComponent, canActivate: [AuthGuard]},
    {path: 'gallery', component: GalleryComponent, canActivate: [AuthGuard]},
    {path: 'testimonials', component: TestimonialsComponent, canActivate: [AuthGuard]},
    {path: 'sign-up', component: SignUpComponent, canActivate: [AuthGuard]},
    {path: 'sign-in', component: SignInComponent, canActivate: [AuthGuard]},
    {path: 'error', component: ErrorComponent, canActivate: [AuthGuard]},
    {path: 'faq', component: FaqComponent, canActivate: [AuthGuard]},
    {path: 'terms-condition', component: TermsConditionsComponent, canActivate: [AuthGuard]},
    {path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [AuthGuard]},
    {path: 'contact', component: ContactComponent, canActivate: [AuthGuard]},

    // Redirect all other routes to the under construction page
    {path: '**', component: UnderConstructionComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }