import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    
    // Check if user is authenticated via localStorage
    const authenticated = sessionStorage.getItem('authenticated');
    
    if (authenticated === 'true') {
      // User is authenticated, allow access
      return true;
    }
    
    // User is not authenticated, redirect to under construction page
    this.router.navigate(['']);
    return false;
  }
}