import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name: String = "";
  email: String = "";
  company: String = "";
  phone: String = "";
  message: String = "";
  loading: Boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.name == "" || this.email == "" || this.company == "" || this.phone == "" || this.message == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill all fields!',
      })
      return;
    }

    if (this.email) {
      let email = this.email;
      let at = email.indexOf("@");
      let dot = email.lastIndexOf(".");
      if (at < 1 || dot < at + 2 || dot + 2 >= email.length) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please enter a valid email address!',
        })
        return;
      }
    }

    if (this.phone) {
      let phone = this.phone;
      if (phone.length < 8 || phone.length > 15) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please enter a valid phone number!',
        })
        return;
      }
      if(isNaN(Number(phone))){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please enter a valid phone number!',
        })
        return;
      }
    }

    this.loading = true;
    let url = 'https://bigfoot.reddotapps.com.sg/api/contact-form-reddotinnovative';
    let data = {
      full_name: this.name,
      company_name: this.company,
      contact_number: this.phone,
      email: this.email,
      msg: this.message,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },

    }).then((response) => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Your message has been sent!',
      })
      this.name = "";
      this.email = "";
      this.company = "";
      this.phone = "";
      this.message = "";
      this.loading = false;
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
      this.loading = false;
    });


  }
}
