<div class="main-banner-three">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h1>Secure IT Solutions For A More Secure Environment</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <div class="slider-btn-wrap">
                                    <a routerLink="/contact" class="box-btn">Contact Us </a>
                                    <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0 col-md-12">
                <div class="banner-image-slider">
                    <owl-carousel-o [options]="bannerImageSlides">
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-1"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-2"></div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>About Us</span>
                        <h2>Innovative It Helping Service All Over the World</h2>
                        <p>Welcome to VisiAi, a premier technology solutions provider based in Malaysia, where innovation 
                            meets reliability. With a strong commitment to excellence and customer satisfaction, we specialize in 
                            delivering a diverse range of services tailored to meet the unique needs of businesses in today’s fast-
                            paced digital landscape.Our diverse expertise spans across hardware, software, artificial intelligence, 
                            and data analytics. With a commitment to delivering high-quality services and products, we have 
                            established ourselves as a trusted partner for leading global brands such as Hewlett Packard 
                            Enterprise (HPE) and HP. </p>
                        <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form variations passages.</p> -->
                    </div>
                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/about-img-2.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="info-area pt-100 pb-70 bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> About Us</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i> Our Vision</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Our Goal</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>VisiAi Provide All Kind of Service</h2>
            <p> At VisiAi, we pride ourselves on our extensive range of services designed to address various
                technological needs.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Authorised Reseller and Business Partner for HPE</h3>
                        <p>VisiAi is an authorized reseller and 
                            business partner for Hewlett Packard Enterprise (HPE), offering cutting-edge technology 
                            solutions tailored to enhance business efficiency. Our partnership ensures access to HPE’s 
                            innovative products and services, empowering organizations to ensuring seamless 
                            integration and optimize their IT infrastructure and drive growth in today’s competitive 
                            landscape.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Authorised Reseller and Business Partner for HP</h3>
                        <p>We VisiAi are an authorized reseller and 
                            business partner for HP, offering a wide range of HP products, including printers, laptops, desktops, 
                            and accessories.
                            <br/><br/> Our partnership ensures access to the latest technology, competitive pricing, and 
                            excellent customer support. Trust us for reliable, high-quality HP solutions for all your business 
                            needs.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Software<br/> Engineering</h3>
                        <p>VisiAI offers comprehensive software engineering services, delivering 
                            tailored solutions to meet business needs.  We work with our clients to understand their 
                            requirements. From concept to deployment, we design scalable, secure, and efficient solutions.
                            Whether enhancing operations, automating processes, or improving customer experiences, we 
                            deliver innovative software that drives business success.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Hardware Engineering</h3>
                        <p>In addition to software solutions, we offer hardware engineering 
                            services that ensure your IT infrastructure is robust and scalable.<br/> Our engineers are experienced in 
                            designing hardware solutions that meet the specific demands of business. From servers and storage 
                            systems to networking devices, we provide solutions that enhance performance and reliability.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> ICT Repair and Maintenance</h3>
                        <p>VisiAI offers reliable ICT repair and maintenance services to 
                            ensure your technology infrastructure runs smoothly. Our expert technicians provide preventive 
                            maintenance, troubleshooting, and quick repairs for hardware, software issues. By minimizing 
                            downtime and optimizing performance, we help businesses maintain efficient operations, ensuring 
                            that their IT systems remain secure and functional.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> AI and Data Analytics</h3>
                        <p>We VisiAI provides cutting-edge AI and data analytics services to help 
                            businesses unlock the full potential of their data. <br/>Our solutions enable automation, optimize 
                            operations, and deliver actionable insights. By leveraging advanced AI models and data-driven 
                            strategies, we empower businesses to make smarter decisions, enhance performance, and drive 
                            strategic growth.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- 
<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going There are many variations of passages of Lorem Ipsum available, but the.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>

<!-- <section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Why Choose VisiAI?</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>At VisiAI, we pride ourselves on delivering tailored solutions that meet the unique needs of
                every client. Our team is composed of experienced professionals with deep expertise in their
                respective fields, and we work closely with our clients to ensure we understand their goals
                and challenges. Our commitment to innovation and excellence drives us to continuously
                improve our offerings, ensuring that we deliver top-tier solutions and services.
                We believe in long-term partnerships, and that’s why we don’t just provide products and
                services; we provide ongoing support to help your business grow and evolve. Whether
                you’re looking to upgrade your IT infrastructure, implement advanced AI solutions, or
                develop custom software, VisiAI is here to be your trusted technology partner.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-case pb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        Design
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        Development
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        Cyber Security
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c1.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Joe’s Company Software Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Ride Share App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Restaurant Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>IT Software Company Development Case</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a routerLink="/case-studies-details"><h3>Parking Management & Web Developing</h3></a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div>
    </div>
</section> -->

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>People Who are Behind the Achievements</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p> -->
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/Rahul.png" alt="team" />
        
                            <ul class="social">
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                                <li><a href="mailto:rahul@visiai.my" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Rahul Talreja</h3>
                            <p>Co - Founder and Director</p>
                        </div>
                    </div>
                </ng-template>         
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/Arif.png" alt="team" />
        
                            <ul class="social">
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                                <li><a href="mailto:arif@visiai.my" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Arif Gafar</h3>
                            <p>Co - Founder and Director</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/Najib.png" alt="team" />
        
                            <ul class="social">
                                <!-- <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li> -->
                                <li><a href="mailto:najib@visiai.my" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>
        
                        <div class="content text-center">
                            <h3>Najib Hussain</h3>
                            <p>Co - Founder and Director</p>
                        </div>
                    </div>
                </ng-template>
       
                
            </owl-carousel-o>
        </div>
    </div>
</section>

<!-- <section class="home-blog-area pt-100 pb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Joe’s Company Software Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>Temperature App UX Studies & Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2024</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>IT Software Company Development Case</h3></a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section> -->

<section class="home-contact-area home-2-contact ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <!-- <h2>Let Us Know About Your Project Idea!</h2> -->
            <p>Get in touch with us today to learn how we can help your business harness the power of
                technology for future success.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" [(ngModel)]="name" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" [(ngModel)]="email" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" [(ngModel)]="phone" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="company_name" id="company_name" [(ngModel)]="company" class="form-control" required placeholder="Company Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" [(ngModel)]="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" [disabled]="loading" (click)="onSubmit()">
                                    <ng-container *ngIf="!loading">Submit</ng-container>
                                    <ng-container *ngIf="loading">
                                  
                                      Loading...
                                    </ng-container>
                                  </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>