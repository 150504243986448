<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Visiai Provide All Kind of Tech Services</h2>
            <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Authorised Reseller and Business Partner for HPE</h3>
                        <p>VisiAi is an authorized reseller and 
                            business partner for Hewlett Packard Enterprise (HPE), offering cutting-edge technology 
                            solutions tailored to enhance business efficiency. Our partnership ensures access to HPE’s 
                            innovative products and services, empowering organizations to ensuring seamless 
                            integration and optimize their IT infrastructure and drive growth in today’s competitive 
                            landscape.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Authorised Reseller and Business Partner for HP</h3>
                        <p>We VisiAi are an authorized reseller and 
                            business partner for HP, offering a wide range of HP products, including printers, laptops, desktops, 
                            and accessories.
                            <br/><br/> Our partnership ensures access to the latest technology, competitive pricing, and 
                            excellent customer support. Trust us for reliable, high-quality HP solutions for all your business 
                            needs.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Software<br/> Engineering</h3>
                        <p>VisiAI offers comprehensive software engineering services, delivering 
                            tailored solutions to meet business needs.  We work with our clients to understand their 
                            requirements. From concept to deployment, we design scalable, secure, and efficient solutions.
                            Whether enhancing operations, automating processes, or improving customer experiences, we 
                            deliver innovative software that drives business success.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Hardware Engineering</h3>
                        <p>In addition to software solutions, we offer hardware engineering 
                            services that ensure your IT infrastructure is robust and scalable.<br/> Our engineers are experienced in 
                            designing hardware solutions that meet the specific demands of business. From servers and storage 
                            systems to networking devices, we provide solutions that enhance performance and reliability.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> ICT Repair and Maintenance</h3>
                        <p>VisiAI offers reliable ICT repair and maintenance services to 
                            ensure your technology infrastructure runs smoothly. Our expert technicians provide preventive 
                            maintenance, troubleshooting, and quick repairs for hardware, software issues. By minimizing 
                            downtime and optimizing performance, we help businesses maintain efficient operations, ensuring 
                            that their IT systems remain secure and functional.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> AI and Data Analytics</h3>
                        <p>We VisiAI provides cutting-edge AI and data analytics services to help 
                            businesses unlock the full potential of their data. <br/>Our solutions enable automation, optimize 
                            operations, and deliver actionable insights. By leveraging advanced AI models and data-driven 
                            strategies, we empower businesses to make smarter decisions, enhance performance, and drive 
                            strategic growth.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12">
                <div class="page-navigation-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/service"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item active"><a class="page-link"routerLink="/service">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/service">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/service">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/service"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div> -->
        </div>
    </div>
</section>