<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Innovative IT Helping Service All Over the World</h2>
                        <p>Welcome to VisiAi, a premier technology solutions provider based in Malaysia, where innovation 
                            meets reliability. With a strong commitment to excellence and customer satisfaction, we specialize in 
                            delivering a diverse range of services tailored to meet the unique needs of businesses in today’s fast-
                            paced digital landscape.Our diverse expertise spans across hardware, software, artificial intelligence, 
                            and data analytics. With a commitment to delivering high-quality services and products, we have 
                            established ourselves as a trusted partner for leading global brands such as Hewlett Packard 
                            Enterprise (HPE) and HP.</p>
                        <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using Content here,content here normal distribution looking at its.</p> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/104048.jpg" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Our Partnerships</h2>
                    <p>As an authorized reseller and business partner for Hewlett Packard Enterprise (HPE) and HP, we bring 
                        you cutting-edge technology solutions that empower organizations to thrive. Our partnership with 
                        these industry leaders allows us to offer a comprehensive suite of products, including servers, 
                        storage solutions, networking equipment, and personal computing devices. We are dedicated to 
                        ensuring that our clients have access to the latest advancements in technology, enabling them to 
                        optimize their operations and drive strategic business growth.</p>
                        <div style="display: flex; flex-direction: row; gap: 30px;">
                            <img src="assets/images/HP.svg" alt="hpe" style="width: 150px; height: auto;"/>
                            <img src="assets/images/HPE.svg" alt="hp" style="width: 150px; height: auto;"/>
                        </div>
                    <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going There are many variations of passages of Lorem Ipsum available, but the.</p> -->
                  
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->


<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <!-- <span>Our Company</span> -->
                        <h2>Our Commitment</h2>
                        <p>At VisiAi, our mission is to be a catalyst for innovation, helping businesses navigate the complexities 
                            of the digital world. We understand that each organization is unique, and we take the time to listen 
                            and understand your specific challenges and goals. Our client-centric approach ensures that we 
                            provide tailored solutions that drive results. 
                            We are not just a service provider; we are your technology partner. Our team is dedicated to building 
                            long-lasting relationships with our clients, grounded in trust and transparency. We believe that by 
                            working together, we can achieve extraordinary outcomes.</p>
                        <!-- <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more or less normal distribution of letters, as opposed to using Content here,content here normal distribution looking at its.</p> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/2150041859.jpg" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Looking Ahead</h2>
                    <p>As technology continues to evolve, so do we. At VisiAi, we are committed to staying ahead of the 
                        curve, continuously improving our offerings to meet the changing needs of our clients. Whether you 
                        are a small business or a large enterprise, we are here to support your journey toward digital 
                        transformation. 
                        Discover the VisiAi difference today, and let us help you unlock the full potential of your technology. 
                        Together, we can navigate the future of business with confidence and innovation.</p>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                    <!-- <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going There are many variations of passages of Lorem Ipsum available, but the.</p> -->
                    <!-- <a routerLink="/contact" class="box-btn">Contact Us</a> -->
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->