import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-home-three',
    templateUrl: './home-three.component.html',
    styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
    name: String = "";
    email: String = "";
    company: String = "";
    phone: String = "";
    message: String = "";
    loading: Boolean = false;

    constructor() { }

    ngOnInit(): void { }
    onSubmit() {
        if (this.name == "" || this.email == "" || this.company == "" || this.phone == "" || this.message == "") {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all fields!',
            })
            return;
        }

        if (this.email) {
            let email = this.email;
            let at = email.indexOf("@");
            let dot = email.lastIndexOf(".");
            if (at < 1 || dot < at + 2 || dot + 2 >= email.length) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please enter a valid email address!',
                })
                return;
            }
        }

        if (this.phone) {
            let phone = this.phone;
            if (phone.length < 8 || phone.length > 15) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please enter a valid phone number!',
                })
                return;
            }
            if (isNaN(Number(phone))) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please enter a valid phone number!',
                })
                return;
            }
        }

        this.loading = true;
        let url = 'https://bigfoot.reddotapps.com.sg/api/contact-form-reddotinnovative';
        let data = {
            full_name: this.name,
            company_name: this.company,
            contact_number: this.phone,
            email: this.email,
            msg: this.message,
        };
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },

        }).then((response) => {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your message has been sent!',
            })
            this.name = "";
            this.email = "";
            this.company = "";
            this.phone = "";
            this.message = "";
            this.loading = false;
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
            this.loading = false;
        });


    } bannerImageSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoplayTimeout: 2500,
        autoHeight: true,
        items: 1,
        animateOut: 'fadeOut',
        margin: 0,
        navText: [
            "<i class='flaticon-left-arrow'></i>",
            "<i class='flaticon-next-1'></i>"
        ],
    }
    teamSlides: OwlOptions = {
        loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
        loop: true,
        margin: 30,
        nav: false,
        mouseDrag: true,
        items: 1,
        dots: false,
        autoHeight: true,
        autoplay: true,
        smartSpeed: 800,
        autoplayHoverPause: true,
        center: false,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
                margin: 20,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    }

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

    // Video Popup
    isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}